import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import { AuthProvider } from '../../contexts/authContext';
import Mobile from './Mobile.js';

import DesktopView from './DesktopView.js';

//const clientid = "818777142975-nl8137unqsqoi57mqosi248bvud4eppi.apps.googleusercontent.com";


function Landing() {

  const [deviceType, setType] = useState();
  const ua = navigator.userAgent;

  useEffect(() => {
    // This code will run only once after the component mounts
    handleResize();
}, []); // The empty array means this effect will run only once

  function getDeviceType() {
    const ua = navigator.userAgent;
    console.log(window.innerWidth);

    // Check for mobile and tablet based on user agent
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)) {
      
      if (window.innerWidth <= 480) {
        console.log("Mobile");
        return setType('Mobile');
      } else {
        console.log("Tablet");
        return setType('Tablet');
      }
    }

    if (window.innerWidth <= 480) {
      console.log("Mobile");
      return setType('Mobile');
    } else {
      console.log("Desktop");
      return setType('Desktop');;
    }



    // Otherwise, it's a desktop
    
  }

  const onSuccess = (res) => {
    console.log("User Signed in");
  }

  const onFailure = (res) => {
    console.log("User not Signed in");
  }

  function handleResize(){

    if (window.innerWidth <= 1050) {
      return setType('Mobile');
    } else if (window.innerWidth >= 480 && window.innerWidth <= window.innerHeight) {
      return setType('Tablet');;
    } else {
      return setType("Desktop");

    }
  }
  

  window.addEventListener('resize', handleResize);

  return (
    <div>
      {deviceType === "Mobile" ? (
        <AuthProvider>
          <Mobile/>
        </AuthProvider>
        
      ) : deviceType === "Desktop" ? (
        <AuthProvider>
                  <DesktopView/>

        </AuthProvider>
      ) : (
        <AuthProvider>
          <Mobile/>
        </AuthProvider> // Add an "else" case or handle other device types here
      )}
    </div>
  );
  
}

export default Landing;
