// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCm70028pa9kb8ojrhxMRtrOMxfJat0U_I",
  authDomain: "resevents-e239e.firebaseapp.com",
  databaseURL: "https://resevents-e239e-default-rtdb.firebaseio.com",
  projectId: "resevents-e239e",
  storageBucket: "resevents-e239e.appspot.com",
  messagingSenderId: "1094547143237",
  appId: "1:1094547143237:web:74d96e12f304213639c12c",
  measurementId: "G-M21LW711BM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const storage = getStorage(app);

export { app, auth, storage };