import { FunctionComponent } from 'react';
import ArrowUp from './assets/arrow-up-right.svg';
import ArrowPurp from './assets/arrow-up-right-purple.svg';
import Logo from './assets/bigL.png';
import ActionImage from './assets/image1.png';
import AiIcon from './assets/frame.svg';
import ApexCharts from 'react-apexcharts'; // Import ApexCharts
import app_icon from './assets/u_app_icon.png';

import google from './assets/google.svg';
import ibm from './assets/ibm.svg';
import microsoft from './assets/microsoft.svg';
import nokia from './assets/nokia.svg';

import Open from '@mui/icons-material/ArrowDownwardSharp';
import stamp from './assets/rafiki-stamp.png';

import tick from './assets/vertical-container.svg';
import tickWhite from './assets/vertical-container-white.svg';
import pana from './assets/pana.svg';

import meta from './assets/meta.svg';
import git from './assets/git.svg';
import linkedin from './assets/linkedin.svg';

import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import LOGOUT from '@mui/icons-material/Logout';
import { doSignOut } from '../../firebase/auth';

import './DesktopView.css';

function DesktopView() {
  // TO:DO Create function to show pricing table.

  /* DesktopView.js */

// Dummy data for Pie Chart
const pieChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: [], // Remove labels by setting an empty array
    colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
    legend: {
      show: false, // Disable the legend
    },
    tooltip: {
      enabled: false, // Disable hover tooltips
    },
    dataLabels: {
      enabled: false, // Show only the numbers
      formatter: function (val) {
        return val.toFixed(0); // Format the numbers as integers
      },
    },
  };
    
  
  const pieChartSeries = [12, 19, 3, 5, 2, 3];
  
  // Dummy data for Line Chart
  const lineChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Disable the export/download options toolbar
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [], // Remove categories (empty array removes labels)
      labels: {
        show: false, // Hide x-axis labels
      },
      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide x-axis ticks
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide y-axis labels
      },
    },
    grid: {
      show: true, // Show grid lines
    },
    tooltip: {
      enabled: true, // Keep tooltips enabled to maintain hover effect
      custom: function({ seriesIndex, dataPointIndex, w }) {
        return ''; // Return an empty string to hide the tooltip content
      },
    },
    markers: {
      size: 4, // Add marker dots for better hover visibility
      hover: {
        size: 6, // Increase marker size on hover for better effect
      }
    },
    colors: ['#5B64EB'],
    legend: {
      show: false, // Disable the legend
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
    title: {
      text: undefined, // Remove the chart title
    },
  };
  const lineChartSeries = [
    {
      name: 'Visitors',
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ];

  

  function explorePricing() {}

  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();

  return (
    <div className='uniconn-desktop-body' style={{ overflowX: 'hidden' }}>
      {/* // Ad Banner */}
      {/* <div className='uniconn-desktop-banner'>
          <div style={{ margin: '20px' }}>
              Our AI is only $30 NOW!
          </div>
          <div style={{ width: '100px' }}>

          </div>
          <div
              onClick={explorePricing()}
              style={{ display: 'flex', justifyContent: 'center', textDecoration: 'underline' }}>
              Explore Now
              <img src={ArrowUp} />
          </div>
      </div> */}

      {/* // Nav Header */}
      <div className='uniconn-desktop-nav-header'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
            marginLeft: '200px',
            marginRight: '200px',
          }}
        >
          {/* // Logo & Navs */}
          <div className='uniconn-desktop-sub-nav-header'>
            <img
              height='60vh'
              src={app_icon}
              style={{ cursor: 'pointer', margin: '5px', borderRadius: '5px' }}
            />
            {/* <div style={{ cursor: 'pointer' }}>
                  How it works?
              </div>

              <div style={{ cursor: 'pointer' }}>
                  About Us
              </div>

              <div style={{ cursor: 'pointer' }}>
                  For Businesses
              </div> */}
          </div>

          {/* // Logins */}
          <div className='uniconn-desktop-sub-nav-header' style={{ gap: '10px' }}>
            {/* // Change to My Courses if Logged in */}
            {userLoggedIn ? (
              <>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{ fontFamily: 'League Spartan' }}
                  onClick={() => navigate('/admin-dashboard')}
                >
                  Admin Console
                </div>
                <LOGOUT
                  height='200px'
                  color='black'
                  cursor='pointer'
                  onClick={doSignOut}
                />
              </>
            ) : (
              <>
                <div
                  className='uniconn-desktop-buttonFilled'
                  onClick={() => navigate('/admin-login')}
                >
                  Log in
                  <img src={ArrowUp}/>
                </div>
                {/* <div
                  className='uniconn-desktop-buttonFilled'
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onClick={() => navigate('/admin-login')}
                >
                  Get Started
                  <img src={ArrowUp} />
                </div> */}
              </>
            )}
          </div>
          
        </div>
      </div>

      {/* // Landing & Call to Action */}
      <div
        className='uniconn-desktop-landing'
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {/* // Call to Action */}
        <div
          style={{
            flex: 1,
            marginLeft: '200px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <div
            className='uniconn-desktop-sub-landing-para'
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <img src={AiIcon} style={{ marginBottom: '5px' }} />
            The Ultimate Student Engagement Toolbox
          </div>

          <div className='uniconn-desktop-sub-landing-heading'>
            Measure Student Engagement on Campus
          </div>

          <div
            className='uniconn-desktop-sub-landing-para'
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'start',
              alignItems: 'center',
              fontSize: '20px',
            }}
          >
            Study Student Population • Engage Better • Boost Student Retention
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            {/* <div className='uniconn-desktop-buttonLined'>
                  See How it works?
              </div> */}
            <div
              className='uniconn-desktop-buttonFilled'
              style={{ width: '200px' }}
              onClick={() => window.open('mailto:gverma@mun.ca?subject=Interested%20in%20Uniconn&body=Hello!%20We%20would%20love%20to%20check%20out%20Uniconn.%20When%20can%20we%20meet?')}
              >
              Explore Solutions
              <img src={ArrowUp} />
            </div>
          </div>
          {/* <div
            className='uniconn-desktop-sub-landing-para'
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'start',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            * Get a FREE Course when you Sign Up for the Waitlist
          </div> */}
        </div>

        {/* // Image*/}
        <div style={{ flex: 1, marginRight: '100px' }}>
        <div
        className='uniconn-desktop-charts'
        style={{
          position: 'relative',
          width: '80%',
          height: '400px', // Set a fixed height to handle absolute positioning
        }}
      >
        {/* Pie Chart - on top */}
        <div
          className='uniconn-desktop-pie-chart'
          style={{
            position: 'absolute',
            top: '20%',
            left: '30%',
            zIndex: 2, // Higher z-index to ensure it's on top
          }}
        >
          <ApexCharts
            options={pieChartOptions}
            series={pieChartSeries}
            type='pie'
            width={250}
            height={250}
          />
        </div>

        {/* Line Chart - below */}
        <div
          className='uniconn-desktop-line-chart'
          style={{
            position: 'relative',
            zIndex: 1, // Lower z-index so that Pie Chart appears above it
          }}
        >
          <ApexCharts
            options={lineChartOptions}
            series={lineChartSeries}
            type='line'
            height={350}
          />
        </div>
      </div>
        </div>

      </div>

      {/* // Companies */}
      {/* <div className='uniconn-desktop-companies'>
          <div className= 'companies' style={{marginLeft: '200px', marginRight: '200px'}}>
          <img src={nokia} height={'40px'}/>
          <img src={ibm} height={'60px'}/>
          <img src={google} height={'60px'}/>
          <img src={meta} height={'60px'}/>
          <img src={microsoft} height={'100px'}/>
          </div>
      </div> */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          paddingTop: '200px',
          paddingBottom: '50px',
        }}
      >
        <Open
          color='black'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        />
      </div>

      {/* // How Uniconn Helps */}
      {/* <div
        className='uniconn-desktop-box'
        style={{
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '200px',
            marginLeft: '200px',
            paddingBottom: '100px',
          }}
        >
          <div
            className='uniconn-desktop-sub-heading'
            style={{ textAlign: 'center' }}
          >
            How uniconn Helps!
          </div>
          <p className='uniconn-desktop-para'>
            uniconn is the ultimate student survey that understand your student population better than anyone!
          </p>
          <div style={{ height: '50px' }}></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '100px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '50px',
              }}
            >
              <div className='uniconn-desktop-sub-sub-landing-para'>
                <div className='uniconn-desktop-circle'>
                  <img src={stamp} alt='stamp' />
                </div>
                <div>Personalized Learning Paths</div>
                <div className='uniconn-desktop-sub-para'>
                  Create your own learning journey that adapts to your pace and
                  goals.
                </div>
              </div>
              <div className='uniconn-desktop-sub-sub-landing-para'>
                <div className='uniconn-desktop-circle'>
                  <img src={stamp} alt='stamp' />
                </div>
                <div>Combat Imposter Syndrome</div>
                <div className='uniconn-desktop-sub-para'>
                  Build confidence with courses tailored to validate your skills
                  and expertise.
                </div>
              </div>
              <div className='uniconn-desktop-sub-sub-landing-para'>
                <div className='uniconn-desktop-circle'>
                  <img src={stamp} alt='stamp' />
                </div>
                <div>Domain-Specific Knowledge</div>
                <div className='uniconn-desktop-sub-para'>
                  Wanna learn a skill for a specific purpose? We got you!
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '50px',
              }}
            >
              <div className='uniconn-desktop-sub-sub-landing-para'>
                <div className='uniconn-desktop-circle'>
                  <img src={stamp} alt='stamp' />
                </div>
                <div>Multilingual Content</div>
                <div className='uniconn-desktop-sub-para'>
                  Learn in over 80 National & Regional languages.
                </div>
              </div>
              <div className='uniconn-desktop-sub-sub-landing-para'>
                <div className='uniconn-desktop-circle'>
                  <img src={stamp} alt='stamp' />
                </div>
                <div>Get Help Quicker</div>
                <div className='uniconn-desktop-sub-para'>
                  Stuck somewhere? Ask AI and get answers instantly!
                </div>
              </div>
              <div className='uniconn-desktop-sub-sub-landing-para'>
                <div className='uniconn-desktop-circle'>
                  <img src={stamp} alt='stamp' />
                </div>
                <div>Experience Courses</div>
                <div className='uniconn-desktop-sub-para'>
                  Experience courses made by others that actually helped them!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* // Pricing  */}
      {/* <div
        className='uniconn-desktop-pricing-box'
        style={{
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '200px',
            marginLeft: '200px',
            paddingBottom: '100px',
          }}
        >
          <div
            className='uniconn-desktop-sub-heading'
            style={{ textAlign: 'center', color: 'black' }}
          >
            Explore Our Pricing Options!
          </div>
          <p className='uniconn-desktop-para'>
            We offer budget-friendly solutions tailored to your needs.
            Transparent pricing and exceptional value without compromising on
            quality.
          </p>
          <div style={{ height: '50px' }}></div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <div
              className='uniconn-desktop-pricing-card'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '10px',
              }}
            >
              <div className='uniconn-desktop-pricing-sub-card'>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{ height: '30px' }}
                >
                  Free
                </div>
                <div className='uniconn-desktop-pricing-points-text'>
                  <br />
                  <br />
                  <span style={{ fontSize: '50px', fontWeight: 'bold' }}>
                    15 Days Free
                  </span>
                </div>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  onClick={() => window.open('https://tally.so/r/mJRYyK')}
                >
                  Sign Up for the Waitlist!
                  <img src={ArrowUp} />
                </div>
              </div>
              <div className='uniconn-desktop-pricing-points'>
                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Only 1 Course
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Limited Assessments
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  $30 per Month after Trial Ends
                </div>
              </div>
            </div>

            <div
              className='uniconn-desktop-pricing-card'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '10px',
                backgroundColor: '#5B64EB',
              }}
            >
              <div
                className='uniconn-desktop-pricing-sub-card'
                style={{ backgroundColor: '#535BD6' }}
              >
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{
                    backgroundColor: 'white',
                    color: '#3a44c2',
                    border: '1px solid #5B64EB',
                    height: '30px',
                  }}
                >
                  Standard Plan
                </div>
                <div className='uniconn-desktop-pricing-points-text'>
                  <span
                    style={{
                      fontSize: '20px',
                      textDecoration: 'line-through',
                      color: '#D2D2D5',
                      fontWeight: '500',
                    }}
                  >
                    $40 / Month
                  </span>
                  <br />
                  <br />
                  <span
                    style={{
                      fontSize: '50px',
                      fontWeight: 'bold',
                      color: 'white',
                    }}
                  >
                    $30
                  </span>
                  <span
                    style={{
                      fontSize: '20px',
                      marginLeft: '5px',
                      color: 'white',
                      fontWeight: '700',
                    }}
                  >
                    / Month
                  </span>
                </div>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: 'white',
                    color: '#5B64EB',
                  }}
                  onClick={() => window.open('https://tally.so/r/mJRYyK')}
                >
                  Sign Up for the Waitlist!
                  <img src={ArrowPurp} />
                </div>
              </div>
              <div className='uniconn-desktop-pricing-points'>
                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    color: 'white',
                  }}
                >
                  <img src={tickWhite} />
                  Unlimited Courses
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    color: 'white',
                  }}
                >
                  <img src={tickWhite} />
                  Limited Assessments
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    color: 'white',
                  }}
                >
                  <img src={tickWhite} />
                  Unlimited Learning
                </div>
              </div>
            </div>

            <div
              className='uniconn-desktop-pricing-card'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '10px',
              }}
            >
              <div className='uniconn-desktop-pricing-sub-card'>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{ height: '30px' }}
                >
                  Premium Plan
                </div>
                <div className='uniconn-desktop-pricing-points-text'>
                  <br />
                  <br />
                  <span style={{ fontSize: '50px', fontWeight: 'bold' }}>
                    $100
                  </span>
                  <span
                    style={{
                      fontSize: '20px',
                      marginLeft: '5px',
                      fontWeight: '700',
                    }}
                  >
                    / Year
                  </span>
                </div>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  onClick={() => window.open('https://tally.so/r/mJRYyK')}
                >
                  Sign Up for the Waitlist!
                  <img src={ArrowUp} />
                </div>
              </div>
              <div className='uniconn-desktop-pricing-points'>
                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Unlimited Courses
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Unlimited Assessments
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Unlimited Learning
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  All Year Long!
                </div>
              </div>
            </div>

            <div
              className='uniconn-desktop-pricing-card'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '10px',
              }}
            >
              <div className='uniconn-desktop-pricing-sub-card'>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{
                    backgroundColor: 'white',
                    color: '#3a44c2',
                    border: '1px solid #5B64EB',
                    height: '30px',
                  }}
                >
                  Enterprise
                </div>
                <div className='uniconn-desktop-pricing-points-text'>
                  <br />
                  <br />
                  <span style={{ fontSize: '50px', fontWeight: 'bold' }}>
                    Talk to Us
                  </span>
                </div>
                <div
                  className='uniconn-desktop-buttonFilled'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  onClick={() =>
                    window.open(
                      'mailto:support@uniconn.com?subject=Uniconn%20AI%20for%20Employee%20Training%20and%20Professional%20Development',
                      '_blank'
                    )
                  }
                >
                  Contact Us
                  <img src={ArrowUp} />
                </div>
              </div>
              <div className='uniconn-desktop-pricing-points'>
                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Train Your Employees
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Role Specific Training
                </div>

                <div
                  className='uniconn-desktop-pricing-points-text'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <img src={tick} />
                  Tailored for Your Business
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* // Last Call */}
      {/* <div
        className='uniconn-desktop-box'
        style={{
          margin: '0 auto',
          display: 'flex',
          height: '50vh',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '200px',
            marginLeft: '200px',
            paddingBottom: '100px',
          }}
        >
          <div
            style={{
              marginLeft: '200px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <div
              className='uniconn-desktop-sub-landing-heading'
              style={{ fontSize: '50px' }}
            >
              Find your perfect course with Uniconn
            </div>

            <div
              className='uniconn-desktop-sub-landing-para'
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'start',
                alignItems: 'center',
                fontSize: '18px',
                marginRight: '400px',
                fontWeight: '400',
              }}
            >
              Discover your career possibilities with Uniconn! Begin today and
              take the next step in your career journey.
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '50vw',
                gap: '10px',
              }}
            >
              <div
                className='uniconn-desktop-buttonFilled'
                onClick={() => window.open('https://tally.so/r/mJRYyK')}
              >
                Sign Up for the Waitlist!
                <img src={ArrowUp} />
              </div>
            </div>
          </div>

          <div style={{ marginRight: '200px' }}>
            <img height='450px' src={pana} />
          </div>
        </div>
      </div> */}

      <div className='uniconn-desktop-frameParent'>
        <div className='uniconn-desktop-w6Copy2Parent'>
          <img className='uniconn-desktop-w6Copy2' alt='' src={app_icon} />
          <div className={'ourTailoredEducationalProgrWrapper'}>
            <div className={'ourTailoredEducational'}>
              uniconn aims to boost retention and graduation rates
              in universities by introducing administrators with
              cutting edge technology that helps them understand their
              student population better and make smarter, data driven decsions!
            </div>
          </div>
        </div>
        <div className={'siteLinkParent'}>
          <div className={'siteLink'}>
            <div className={'quickLink'}>Quick Link</div>
            <div className={'point'}>
              <div className={'courses'}>How it works</div>
              <div className={'courses'}>Testimonial</div>
            </div>
          </div>

          <div className={'importantLink'}>
            <div className={'quickLink'}>Important Links</div>
            <div className={'point'}>
              <div className={'courses'} style={{cursor: "pointer"}} onClick={() => navigate("/privacy_policy")}>Privacy Policy</div>
            </div>
          </div>
        </div>
      </div>

      {/* // Copyright */}
      <div className={'uniconn-desktop-allRightsReservedParent'}>
        <div className={'uniconn-desktop-allRights'}>
          © 2024 uniconn. All rights reserved.
        </div>
        <div className={'contactInfo'}>
          <div className={'followUs'}>Follow Us</div>
          <div className={'social'}>
            <img className={'iconfb'} alt='' src={meta} />
            <img className={'iconfb'} alt='' src={linkedin} />
            <img className={'iconfb'} alt='' src={git} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopView;
