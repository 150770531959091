
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext';
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI Spinner
import ReplayIcon from '@mui/icons-material/Replay';
import { MenuItem, Select } from '@mui/material';
import {styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Chart from 'react-apexcharts';
import "./Dash.css";

function LifeCycleChart() {

    const { currentUser, idToken } = useAuth();

    // Data for the line chart (student lifecycle clicks over 13 weeks)
    const [categoriesData, setCategoriesData] = useState(
        {
            'Automotive': [0, 0, 0, 0, 0, 0, 0, 24, 28, 0, 0, 0, 0],
            'Business & Professional': [66, 60, 212, 275, 178, 85, 82, 343, 553, 164, 92, 77, 96],
            'Charity & Causes': [27, 44, 272, 93, 56, 56, 104, 29, 168, 44, 78, 24, 33],
            'Family & Education': [0, 36, 171, 441, 158, 47, 75, 55, 141, 164, 169, 71, 83],
            'Fashion & Beauty': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            'Film, Media & Entertainment': [146, 404, 189, 343, 141, 132, 101, 106, 94, 133, 87, 55, 68],
            'Food & Drink': [49, 221, 463, 594, 352, 215, 142, 175, 184, 203, 219, 255, 135],
            'Government & Politics': [0, 1, 0, 0, 10, 0, 0, 0, 0, 4, 14, 0, 7],
            'Health & Wellness': [88, 132, 334, 272, 167, 80, 91, 60, 169, 208, 70, 139, 223],
            'Music': [55, 162, 116, 126, 49, 61, 33, 46, 47, 50, 42, 24, 69],
            'Performing & visual arts': [0, 82, 88, 58, 58, 44, 38, 39, 44, 114, 63, 16, 28],
            'Religion & spirituality': [0, 24, 163, 62, 3, 1, 19, 0, 6, 154, 52, 22, 5],
            'Science & technology': [21, 37, 372, 497, 201, 54, 75, 172, 141, 164, 52, 98, 2],
            'Travel & outdoor': [0, 0, 0, 0, 0, 0, 0, 21, 0, 0, 24, 33, 33],
            'Sports & fitness': [20, 176, 0, 65, 47, 40, 14, 48, 34, 10, 26, 21, 35]
        });

    const generateSemesterOptions = (currentDate) => {
        const fixedStartYear = 2024; // Always start with Winter 2024
        const fixedStartSemester = { label: `Winter ${fixedStartYear}`, timestamp: new Date(fixedStartYear, 0, 8).getTime() / 1000 };

        const year = currentDate.getFullYear();
        const dynamicSemesters = [
            { label: `Summer ${year}`, timestamp: new Date(year, 4, 8).getTime() / 1000 },  // May 8th
            { label: `Fall ${year}`, timestamp: new Date(year, 8, 8).getTime() / 1000 },    // September 8th
        ];

        // If it's past September, also add the Winter semester for the next year
        if (currentDate.getMonth() >= 8) {
            dynamicSemesters.push({ label: `Winter ${year + 1}`, timestamp: new Date(year + 1, 0, 8).getTime() / 1000 });
        }

        const now = currentDate.getTime() / 1000;

        // Find the current or most recent semester
        let lastSemester;
        for (let i = dynamicSemesters.length - 1; i >= 0; i--) {
            const semesterStart = dynamicSemesters[i].timestamp;
            const semesterEnd = semesterStart + 13 * 7 * 24 * 60 * 60; // 13 weeks in seconds
            if (now >= semesterStart) {
                lastSemester = dynamicSemesters[i];
                break;
            }
        }

        // If we don't find a current semester, use the last available semester
        if (!lastSemester) {
            lastSemester = dynamicSemesters[dynamicSemesters.length - 1];
        }

        return [fixedStartSemester, ...dynamicSemesters.slice(0, dynamicSemesters.indexOf(lastSemester) + 1)];
    };

    const currentDate = new Date();
    const [semesters, setSemesters] = useState(generateSemesterOptions(currentDate));
    const [selectedSemester, setSelectedSemester] = useState(semesters[semesters.length - 1]);  // Default to the last available semester

    const handleChange = (event) => {
        // We now get both the label and the timestamp directly from the event value
        const selectedOption = event.target.value;
        setSelectedSemester(selectedOption);
    };

    // Create an array for the series data for the line chart
    const lineSeries = Object.keys(categoriesData).map(category => ({
        name: category,
        data: categoriesData[category]
    }));

    // X-axis labels for weeks (13 weeks)
    const weekLabels = Array.from({ length: 13 }, (_, i) => `Week ${i + 1}`);

    // Line chart options
    const lineChartOptions = {
        chart: {
            type: 'line',
            height: 350
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        xaxis: {
            categories: weekLabels,
            title: {
                text: 'Weeks'
            }
        },
        yaxis: {
            title: {
                text: 'Clicks'
            }
        },
        markers: {
            size: 4
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (val) {
                    return `${val} clicks`;
                }
            }
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 0
        },
        colors: ['#00A6B4', '#009F9D', '#006B6B', '#005F5F', '#00AEFF', '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8bc1f7', '#519de9', '#06c', '#004b95', '#002f5d']
    };



    const [fetchSLCR, setFetchSLCR] = useState();

    const [refresh, setRefresh] = useState(false); // Toggle this to trigger useEffect

    const handleRefresh = () => {
        setRefresh(prev => !prev); // Toggle the `refresh` state to re-trigger useEffect
    };

    // Get Student Life Cycle Report
    useEffect(() => {
        const getStudentLifeCycleReport = async () => {
            setFetchSLCR(true);
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/getStudentLifeCycleReport',
                    { startDate: selectedSemester['timestamp'] },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );


                setCategoriesData(response.data);
                setFetchSLCR(false);
            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };

        getStudentLifeCycleReport();
    }, [selectedSemester, idToken, refresh]);

    const menuProps = {
        PaperProps: {
          style: {
            maxHeight: 200,  // Controls the height of the dropdown
            width: 250,      // Width of the options tray
            borderRadius: 8, // Rounded corners for the tray
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
            fontSize: "10px"
          },
        },
      };

      const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .MuiTooltip-tooltip`]: {
          fontSize: '12px',       // Larger font size
          padding: '15px',        // More padding for a bigger box
          maxWidth: '250px',      // Set a max-width to control the box size
          backgroundColor: '#fff', // Darker background for the tooltip
          color: '#333',          // White text color
          borderRadius: "10px",
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Slight shadow for a modern look
        }
      });
      
      const HoverableText = styled('span')({
        color: '#000',            // Default color
        transition: 'color 0.3s ease', // Smooth color transition
        cursor: 'pointer',
        '&:hover': {
          color: '#1e90ff',       // Color change on hover (can adjust to your liking)
        },
      });

    return (
        <>
            <div style={{ margin: "20px", borderRadius: "25px", backgroundColor: "#f9f9f9" }}>
                <div style={{ paddingLeft: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                    <Select
                        value={selectedSemester}
                        onChange={handleChange}
                        disableUnderline
                        variant="standard"
                        MenuProps={menuProps}
                        sx={{
                            marginTop: '20px',
                            marginRight: "0px",
                            fontSize: '25px',     // Larger text size
                            lineHeight: '1',      // Tighter line height
                            '& .MuiSelect-select': {
                                padding: '5px',   // Less padding inside the select box
                            },
                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Remove border
                            backgroundColor: 'transparent',
                        }}
                    >
                        {semesters.map((option) => (
                            <MenuItem key={option.label} value={option}>
                                <h4 style={{ margin: 0, padding: 0 }}>{option.label}</h4>  {/* Larger text */}
                            </MenuItem>
                        ))}
                    </Select>
                    <button className="event-action-button" style={{ marginLeft: "20px", backgroundColor: "#f0f0f0" }} onClick={handleRefresh}>
                        {fetchSLCR ? (
                            <CircularProgress size={"16px"} thickness={10} />
                        ) : (
                            <ReplayIcon style={{ fontSize: '16px' }}/>
                        )}
                    </button>
                    </div>

                    <h3 style={{ marginRight: "40px", display: "flex", justifyContent: "space-between" }}>
      <HoverableText>
        <CustomTooltip title="Total Number of Clicks received by different categories of events in each week in a semester.">
          <span>?</span>
        </CustomTooltip>
      </HoverableText>
    </h3>


                    
                </div>

                {/* Line Chart: Student Life Cycle Clicks */}
                <Chart
                    options={lineChartOptions}
                    series={lineSeries}
                    type="line"
                    height={500}
                    style={{ padding: "20px" }}
                />

            </div>

           
        </>
    );
}

export default LifeCycleChart;

