import React, { useContext, useEffect, useState } from "react";
import { auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [idToken, setIdToken] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe;
    }, []);

    // Refresh the token every 50 minutes (Firebase tokens expire in 60 minutes)
    useEffect(() => {
        let refreshTokenInterval;
        if (currentUser) {
            refreshTokenInterval = setInterval(async () => {
                const token = await currentUser.getIdToken(true); // Refresh the token
                setIdToken(token);
            }, 50 * 60 * 1000); // 50 minutes
        }

        return () => clearInterval(refreshTokenInterval); // Cleanup interval on unmount
    }, [currentUser]);

    async function initializeUser(user) {
        if (user) {
            const token = await user.getIdToken(); // Get initial token
            setCurrentUser({ ...user });
            setIdToken(token);
            setUserLoggedIn(true);
        } else {
            setCurrentUser(null);
            setIdToken(null);
            setUserLoggedIn(false);
        }
        setLoading(false);
    }

    const value = {
        currentUser,
        userLoggedIn,
        loading,
        idToken,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
