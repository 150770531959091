import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doSignInWithEmailAndPassword, doSignInWithGoogle } from "../../firebase/auth";
import { useAuth } from "../../contexts/authContext";
import "./AdminLogin.css"; // Import your CSS file for styling

function AdminLogin() {
    const { userLoggedIn } = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onGoogleSignIn = async (e) => {
        e.preventDefault();
        if (isSigningIn) return;
        setIsSigningIn(true);

        try {
            const result = await doSignInWithGoogle();
            const user = result.user;

            if (user) {
                navigate('/admin-dashboard');
            }

        } catch (error) {
            console.error('Google Sign-In Error:', error);
            setErrorMessage(error.message);
        } finally {
            setIsSigningIn(false);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isSigningIn) return;
        setIsSigningIn(true);

        try {
            const result = await doSignInWithEmailAndPassword(email, password);
            const user = result.user;

            setEmail('');
            setPassword('');
            navigate("/admin-dashboard");

        } catch (error) {
            console.error('Email Sign-In Error:', error);
            setErrorMessage(error.message);
        } finally {
            setIsSigningIn(false);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={onSubmit} className="login-form">
                <h1 className="login-title">Login</h1>
                <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-field"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-field"
                />
                <div className="button-container">
                    <button type="submit" className="submit-button">
                        {isSigningIn ? "Signing In..." : "Sign In"}
                    </button>
                    <button onClick={onGoogleSignIn} className="google-signin-button">
                        Sign In with Google
                    </button>
                    <button onClick={() => navigate('/admin-signup')} className="signup-button">
                        Sign Up
                    </button>
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </form>
        </div>
    );
}

export default AdminLogin;
