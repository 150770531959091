import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "./eventView.css"
import uniconn_icon from '../../assets/app_icon.png';

import { Divider } from '@mui/material';

const EventPage = () => {
    const { eventID, host } = useParams(); // Get eventID from URL params
    const [eventObject, setEventObject] = useState({}); // Initialize state as an empty object
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    const getGoogleMapsUrl = (lat, lng) => {
        return `http://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lng}`
    };

    useEffect(() => {
        const getEvent = async () => {
            try {
                const response = await axios.post(`https://us-central1-resevents-e239e.cloudfunctions.net/getEventInfoHttps?eventID=${eventID}`);
                console.log(response);
                setEventObject(response.data);
            } catch (error) {
                console.error('Error fetching event data:', error);
                setError('Failed to load event data');
            } finally {
                setLoading(false); // Set loading to false after request is complete
            }
        };

        getEvent();
    }, [eventID]); // Add eventID to dependency array

    function getDate(timestamp) {
        const date = new Date(timestamp * 1000);

        // Format the date
        const day = date.toLocaleDateString("en-US", { weekday: 'long' });
        const month = date.toLocaleDateString("en-US", { month: 'long' });
        const dayOfMonth = date.toLocaleDateString("en-US", { day: 'numeric' });
        const formattedDate = `${day}, ${month} ${dayOfMonth}`;

        // Format the time
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        return { formattedDate, formattedTime };
    }

    const defaultUrl = 'https://us-central1-resevents-e239e.cloudfunctions.net/redirectToAppStore';

    // Function to handle button click
    const handleButtonClick = () => {
        // Get the registration link from eventObject
        const registrationLink = eventObject['registrationLink'];

        // Determine which URL to open
        const urlToOpen = registrationLink ? registrationLink : defaultUrl;

        // Open the URL in a new tab
        window.open(urlToOpen, '_blank');
    };

    const parseDescription = (text) => {
        // Regular expression to match URLs
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

        // Replace URLs in the text with <a> elements
        return text.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#6fa8dc", textDecoration: 'none' }}>
                        {part}
                    </a>
                );
            }
            return part;
        });
    };


    const formatCategories = (categories) => {
        // Check if categories is an array and not empty
        return Array.isArray(categories) && categories.length > 0
            ? categories.join(', ') // Join array elements with commas
            : 'No categories available'; // Fallback text if empty or not an array
    };

    const formatInPersonLocations = (locations) => {
        if (!Array.isArray(locations) || locations.length === 0) {
            return 'No locations available';
        }
        const handleLinkClick = (lat, lng) => {
            window.open(getGoogleMapsUrl(lat, lng), '_blank');
        };

        return locations.map((location, index) => (
            <div
                key={index}
                style={{ color: "white", cursor: "pointer", marginBottom: "5px" }} // Optional styling
                onClick={() => handleLinkClick(location.lat, location.lng)} // Call the function on click
            >
                <a
                    href={getGoogleMapsUrl(location.lat, location.lng)} // Optional: allows for middle-clicks and other default link behaviors
                    style={{ color: "white", textDecoration: "none" }} // White color, no underline
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Security feature for opening new tabs
                >
                    {location.formattedAddress}
                </a>
            </div>
        ));
    };

    const formatOnlineLinks = (links) => {
        if (!Array.isArray(links) || links.length === 0) {
            return 'No online links available';
        }

        return links.map((link, index) => (
            <div
                key={index}
                style={{ marginBottom: "5px" }} // Optional styling
            >
                <a
                    href={link}
                    style={{ color: "white", textDecoration: "none", color: "#6fa8dc" }} // White color, no underline
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Security feature for opening new tabs
                >
                    {link}
                </a>
            </div>
        ));
    };

    const formatHybridData = (hybrid) => {
        const { online, inPerson } = hybrid;
        return (
            <>
                {online && online.length > 0 && (
                    <div className='in-person-locations'>
                        {formatOnlineLinks(online)}
                    </div>
                )}
                {inPerson && inPerson.length > 0 && (
                    <div className='in-person-locations'>
                        {formatInPersonLocations(inPerson)}
                    </div>
                )}
            </>
        );
    };

    const handleDownloadClick = () => {
        window.open(defaultUrl, '_blank');
    };




    if (loading) {
        return (
            <div>
                {/* <div className='button-register' style={{ height: "70px", backgroundColor: "white", color: "black", display: "flex", flexDirection: "column", paddingBottom: "0px" }} onClick={window.open(defaultUrl, '_blank')}>
                Download uniconn!<br></br>
                <div style={{ fontSize: "12px" }}>
                    and keep track of all your uni events!
                </div>

            </div> */}

<div className='button-register' style={{ height: "80px", backgroundColor: "white", color: "black", display: "flex", flexDirection: "column" }} onClick={(handleDownloadClick)}>
                    Download uniconn!<br></br>
                    <div style={{ fontSize: "12px" }}>
                        and keep track of all your uni events!
                    </div>

                </div>
                <div className='event-poster' style={{ height: "300px" }}>
                    <div style={{ backgroundColor: "#272727", height: "300px", borderRadius: "20px" }}>

                    </div>
                </div>
                <div className='event-title' >
                    <div style={{ backgroundColor: "#272727", height: "50px", borderRadius: "10px", marginRight: "15px" }}>

                    </div>
                </div>
                <div className='event-host'>
                    <div style={{ backgroundColor: "#272727", height: "10px", borderRadius: "10px", marginRight: "15px" }}>

                    </div>
                </div>
              


                <div className='event-datetime'>
                    <div style={{ backgroundColor: "#272727", height: "60px", borderRadius: "10px", marginRight: "15px" }}>

                    </div>

                </div>
              




                <div className='desc'>
                    <div style={{ backgroundColor: "#272727", height: "400px", borderRadius: "10px", marginTop: "10px" }}>

                    </div>
                </div>
                <div className='location'>
                <div style={{ backgroundColor: "#272727", height: "50px", borderRadius: "10px", marginRight:"15px" }}>

</div>

                </div>

                <hr className='divider'></hr>

                <div className='event-host' style={{ paddingBottom: "100px" }}>
                    uniconn 2024

                </div>

            </div>
        ); // Render a loading indicator while fetching data
    }

    if (error) {
        return <div>{error}</div>; // Render error message if fetching fails
    }



    return (
        <div>
            {/* <div className='button-register' style={{ height: "70px", backgroundColor: "white", color: "black", display: "flex", flexDirection: "column", paddingBottom: "0px" }} onClick={window.open(defaultUrl, '_blank')}>
                Download uniconn!<br></br>
                <div style={{ fontSize: "12px" }}>
                    and keep track of all your uni events!
                </div>

            </div> */}

            <div className='event-poster'>
                <img
                    src={eventObject['poster']}
                    style={{
                        width: '100%',
                        height: 'auto',
                        border: '20px', // 20px border
                        borderRadius: "20px",
                        // 10px margin on all sides
                    }}
                    alt="Event Poster"
                />
            </div>
            <div className='event-title'>
                {eventObject['title']}
            </div>
            <div className='event-host'>
                Hosted by {host}
            </div>
            {eventObject['adult'] && <div className='count-box'>
                19+
            </div>}


            <div className='attributes'>
                {eventObject['eventType']}, {formatCategories(eventObject['categories'])}
            </div>


            <div className='event-datetime'>
                Date & Time
                <div>
                    {eventObject['dateTime'].map((event, index) => {
                        const start = getDate(event.start);
                        const end = getDate(event.end);

                        return (
                            <div key={index} className='event-datetime-entry'>
                                <span>{start.formattedDate}</span><br />
                                <span className='event-time'>{start.formattedTime} - {end.formattedTime}</span><br />
                                {/* Add more details if needed */}
                            </div>
                        );
                    })}
                </div>

            </div>
            {/* <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
                <div className='count-box' onClick={handleButtonClick}>
                    {eventObject['goingCount']}<br></br>
                    Going
                </div>
                <div className='count-box' onClick={handleButtonClick}>
                    {eventObject['savedCount']}<br></br>
                    Saves
                </div>
                <div className='count-box' onClick={handleButtonClick}>
                    {eventObject['clicks']}<br></br>
                    Clicks
                </div>
            </div> */}

            <div className='button-register' style={{ height: "80px", backgroundColor: "white", color: "black", display: "flex", flexDirection: "column" }} onClick={(handleDownloadClick)}>
                Download uniconn!<br></br>
                <div style={{ fontSize: "12px" }}>
                    Discover all your uni events!
                </div>

            </div>
            <div className='button-register' onClick={handleButtonClick}>
                Register ↗️
            </div>

            <hr className='divider' />

            <div className='desc'>
                Description 💬
                <div className='desc-text'>
                    {parseDescription(eventObject['desc'])}

                </div>
            </div>
            <hr className='divider' />
            <div className='location'>
                Location 📍
                {eventObject['platformType'] === 'inPerson' && (
                    <div className='in-person-locations'>
                        {formatInPersonLocations(eventObject['inPerson'])}
                    </div>

                )}
                {eventObject['platformType'] === 'online' && eventObject['online'] && (
                    <div className='in-person-locations'>
                        {formatOnlineLinks(eventObject['online'])}
                    </div>
                )}

                {eventObject['platformType'] === 'hybrid' && eventObject['hybrid'] && (
                    <div>
                        {formatHybridData(eventObject['hybrid'])}
                    </div>
                )}

            </div>

            <hr className='divider'></hr>

            <div className='button-register' style={{ height: "50px", backgroundColor: "white", color: "black", display: "flex", flexDirection: "column" }} onClick={(handleDownloadClick)}>
                Get uniconn!
            </div>
            <div className='event-host' style={{ paddingBottom: "100px" }}>
                uniconn 2024

            </div>

        </div>
    );
};

export default EventPage;
