import React from 'react';
import './PrivacyPolicy.css'; // Ensure this file includes the styles you've shared
import app_icon from '../Landing/assets/u_app_icon.png';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {

    // Convert new lines to <br /> for rendering in JSX
    const policyText = `
      This Privacy Policy outlines the types of information we collect, how we use it, and the choices you can make about your data. By agreeing in-app, you agree to the terms of this Privacy Policy.<br /><br />
      1. Information We Collect<br />
      Account Information: When you create an account, we collect your email, password, and if you choose, you can log in using your Google or Apple account. We use Google's Firebase Authentication to authenticate you.<br />
      Profile Information: Users can create profiles with usernames, email addresses, full names, categories of events they are interested in, and tags related to events and activities they may be interested in.<br /><br />
      Student/Organizer Information: If you identify as a student or organizer, we collect additional information including your Student Number, University Name, and, optionally, your faculty, degree level (undergraduate, graduate, or other), and whether you are an international student or otherwise. This information is stored in a secure database and is used solely for data collection and statistical analysis, which can be accessed by administrators through the UNICONN administrative analytics portal. Please note, while we collect Student Numbers, we do not have access to any university-specific systems or data.<br /><br />
      Device ID for Notifications: If you enable notifications and provide permission, we may collect your device ID to send notifications via a third-party service called Pushy.me and Firebase Cloud Messaging.<br /><br />
      Event Information: When events are created, we collect event title, description, location (online, in-person, or hybrid), URLs, dates, and times. UNICONN also records engagement posts posted on event feeds and records details about those posts.<br /><br />
      QR Code for Attendance: Upon registration, you are provided with a unique QR code that is always available within the app. This QR code can be scanned by event organizers at the event’s entrance to mark your attendance. The QR code is used to verify your presence at events and links to your profile and registration details. This attendance data, including the event name, date, time, and your associated Student Number or profile details, is recorded and accessible through the UNICONN administrative analytics portal.<br /><br />
      2. How We Use Your Information<br />
      We use the collected information for the following purposes:<br />
      - To create and manage user accounts.<br />
      - To personalize user experience and show relevant events based on user preferences.<br />
      - To send notifications about events and activities, provided you have given permission.<br />
      - To improve our services, troubleshoot technical problems, and analyze user trends.<br />
      - To collect and analyze data related to student/organizer activities and demographics for administrative purposes.<br />
      - To verify event attendance using QR codes and manage attendance records.<br /><br />
      3. Sharing of Your Information<br />
      We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share aggregated, non-personally identifiable information with third parties for analytics purposes. Your information, including Student Numbers and event attendance data, is only shared with your university as part of our collaboration for event management and student engagement purposes. We do not share this information with other entities outside of this scope.<br /><br />
      4. Security<br />
      We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.<br /><br />
      5. Your Choices<br />
      You can update or delete your account information and preferences within the App settings.<br /><br />
      You can view and search events and their details without having to create an account. You will be required to create an account to enjoy the added benefits of UNICONN.<br /><br />
      You can opt out of receiving notifications by adjusting your device and app settings.<br /><br />
      You can request to delete your account and personal data in-app.<br /><br />
      6. Changes to This Privacy Policy<br />
      We may update this Privacy Policy from time to time. Any changes will be posted within the App, and your continued use of the App after such modifications will constitute your acknowledgment of the modified Privacy Policy.<br /><br />
      7. Contact Us<br />
      If you have any questions or concerns about this Privacy Policy, please contact us at gverma@mun.ca.<br /><br />
      September 2024<br />
      This Privacy Policy is effective as of the date mentioned above and supersedes all earlier versions.
    `;

    const navigate = useNavigate();

    return (
      <div className="event-poster">
        <img
              height='100vh'
              src={app_icon}
              style={{ cursor: 'pointer', margin: '20px', borderRadius: '5px' }}
              onClick={() => navigate("/")}
            />
        <h1 className="event-title">Privacy Policy</h1>
        <div className="event-host">
          <div dangerouslySetInnerHTML={{ __html: policyText }} />
        </div>
        <div className='event-host' style={{ paddingBottom: "100px" }}>
                uniconn 2024

            </div>
      </div>
    );
};

export default PrivacyPolicy;
