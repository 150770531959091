
import './App.css';
import Dash from "../src/components/Admin_Dashboard/Dash.js";
import AdminLogin from "../src/components/Admin_Dashboard/AdminLogin.js";
import { AuthProvider } from './contexts/authContext';
import { AliveScope, KeepAlive } from 'react-activation';


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EventPage from './components/EventView/eventView.js';
import PrivacyPolicy from './components/Policy/PrivacyPolicy.js';
import Landing  from './components/Landing/Landing.js';
import EventAnalytics from './components/EventAnalyics/EventAnalytics.js';
import Events from './components/Admin_Dashboard/Events.js';
import AdminSignup from './components/Admin_Dashboard/AdminSignup.js';
function App() {
  return (
    <AliveScope>

    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/admin-login" element={<AuthProvider><AdminLogin/></AuthProvider>}/>
        <Route path="/admin-signup" element={<AuthProvider><AdminSignup/></AuthProvider>}/>

        <Route path="/admin-dashboard" element={<AuthProvider><Dash/></AuthProvider>}/>

        <Route path="/admin-dashboard/event" element={<KeepAlive><AuthProvider><Events/></AuthProvider></KeepAlive>}/>
        <Route path="/admin-dashboard/event/:eventID" element={<AuthProvider><EventAnalytics/></AuthProvider>}/>



        <Route path="/event/:host/:eventID" Component={EventPage}/>
        <Route path="/privacy_policy" Component={PrivacyPolicy}/>
      </Routes>
    </BrowserRouter>
    </AliveScope>
   
  );
}

export default App;
