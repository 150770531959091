import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext';
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI Spinner
import ReplayIcon from '@mui/icons-material/Replay';
import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Chart from 'react-apexcharts';
import "./Dash.css";

function EventsPerWeek() {
    const { currentUser, idToken } = useAuth();

    // Data for the line chart (number of events per week over 13 weeks)
    const [eventsData, setEventsData] = useState(Array(13).fill(0));  // Initialize with an array of 13 zeros

    const generateSemesterOptions = (currentDate) => {
        const fixedStartYear = 2024; // Always start with Winter 2024
        const fixedStartSemester = { label: `Winter ${fixedStartYear}`, timestamp: new Date(fixedStartYear, 0, 8).getTime() / 1000 };

        const year = currentDate.getFullYear();
        const dynamicSemesters = [
            { label: `Summer ${year}`, timestamp: new Date(year, 4, 8).getTime() / 1000 },  // May 8th
            { label: `Fall ${year}`, timestamp: new Date(year, 8, 8).getTime() / 1000 },    // September 8th
        ];

        // If it's past September, also add the Winter semester for the next year
        if (currentDate.getMonth() >= 8) {
            dynamicSemesters.push({ label: `Winter ${year + 1}`, timestamp: new Date(year + 1, 0, 8).getTime() / 1000 });
        }

        const now = currentDate.getTime() / 1000;

        // Find the current or most recent semester
        let lastSemester;
        for (let i = dynamicSemesters.length - 1; i >= 0; i--) {
            const semesterStart = dynamicSemesters[i].timestamp;
            const semesterEnd = semesterStart + 13 * 7 * 24 * 60 * 60; // 13 weeks in seconds
            if (now >= semesterStart) {
                lastSemester = dynamicSemesters[i];
                break;
            }
        }

        // If we don't find a current semester, use the last available semester
        if (!lastSemester) {
            lastSemester = dynamicSemesters[dynamicSemesters.length - 1];
        }

        return [fixedStartSemester, ...dynamicSemesters.slice(0, dynamicSemesters.indexOf(lastSemester) + 1)];
    };

    const currentDate = new Date();
    const [semesters, setSemesters] = useState(generateSemesterOptions(currentDate));
    const [selectedSemester, setSelectedSemester] = useState(semesters[semesters.length - 1]);  // Default to the last available semester

    const handleChange = (event) => {
        // We now get both the label and the timestamp directly from the event value
        const selectedOption = event.target.value;
        setSelectedSemester(selectedOption);
    };

    // X-axis labels for weeks (13 weeks)
    const weekLabels = Array.from({ length: 13 }, (_, i) => `Week ${i + 1}`);

    // Line chart options
    const lineChartOptions = {
        chart: {
            type: 'line',
            height: 350
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        xaxis: {
            categories: weekLabels,
            title: {
                text: 'Weeks'
            }
        },
        yaxis: {
            title: {
                text: 'Number of Events'
            }
        },
        markers: {
            size: 4
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (val) {
                    return `${val} events`;
                }
            }
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 0
        },
        colors: ['#00A6B4']
    };

    const [fetchSLCR, setFetchSLCR] = useState(false);
    const [refresh, setRefresh] = useState(false); // Toggle this to trigger useEffect

    const handleRefresh = () => {
        setRefresh(prev => !prev); // Toggle the `refresh` state to re-trigger useEffect
    };


    // Fetch the number of events per week
    useEffect(() => {
        const getEventsPerWeek = async () => {
            setFetchSLCR(true);
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/getEventsPerWeek',
                    { startDate: selectedSemester.timestamp },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                setEventsData(response.data); // Update the state with the 13 numbers received
                setFetchSLCR(false);
            } catch (err) {
                console.error(`Error fetching events per week: ${err}`);
                setFetchSLCR(false);
            }
        };

        getEventsPerWeek();
    }, [selectedSemester, idToken, refresh]);

    const menuProps = {
        PaperProps: {
            style: {
                maxHeight: 200,  // Controls the height of the dropdown
                width: 250,      // Width of the options tray
                borderRadius: 8, // Rounded corners for the tray
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                fontSize: "10px"
            },
        },
    };

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .MuiTooltip-tooltip`]: {
            fontSize: '12px',
            padding: '15px',
            maxWidth: '250px',
            backgroundColor: '#fff',
            color: '#333',
            borderRadius: "10px",
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }
    });

    const HoverableText = styled('span')({
        color: '#000',
        transition: 'color 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
            color: '#1e90ff'
        },
    });

    return (
        <>
            <div style={{ margin: "20px", borderRadius: "25px", backgroundColor: "#f9f9f9" }}>
                <div style={{ paddingLeft: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <Select
                            value={selectedSemester}
                            onChange={handleChange}
                            disableUnderline
                            variant="standard"
                            MenuProps={menuProps}
                            sx={{
                                marginTop: '20px',
                                marginRight: "0px",
                                fontSize: '25px',
                                lineHeight: '1',
                                '& .MuiSelect-select': {
                                    padding: '5px'
                                },
                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                backgroundColor: 'transparent',
                            }}
                        >
                            {semesters.map((option) => (
                                <MenuItem key={option.label} value={option}>
                                    <h4 style={{ margin: 0, padding: 0 }}>{option.label}</h4>
                                </MenuItem>
                            ))}
                        </Select>
                        <button className="event-action-button" style={{ marginLeft: "20px", backgroundColor: "#f0f0f0" }} onClick={handleRefresh}>
                            {fetchSLCR ? (
                                <CircularProgress size={"16px"} thickness={10} />
                            ) : (
                                <ReplayIcon style={{ fontSize: '16px' }} />
                            )}
                        </button>
                    </div>

                    <h3 style={{ marginRight: "40px", display: "flex", justifyContent: "space-between" }}>
                        <HoverableText>
                            <CustomTooltip title="Total Number of Events hosted in each week of the semester. It shows events that were going on in a particular week as well.">
                                <span>?</span>
                            </CustomTooltip>
                        </HoverableText>
                    </h3>
                </div>

                {/* Line Chart: Events per Week */}
                <Chart
                    options={lineChartOptions}
                    series={[{ name: 'Number of Events', data: eventsData }]}  // Using eventsData
                    type="line"
                    height={500}
                    style={{ padding: "20px" }}
                />
            </div>
        </>
    );
}

export default EventsPerWeek;
